import { Container, Grid } from "@mui/material";
import Carts from "./Carts";
import OrderPerMonth from "./OrderPerMounth";
import OrderPerStatus from "./OrderPerStatus";
import ProductsPerMonth from "./ProductsPerMonth";
import ProductsPerStatus from "./ProductsPerStatus";

const Dashboard = () => {
    return (
        <Container>
            <Grid container spacing={4}>
                {/* Adjust the grid item sizes as needed */}
                <Grid item xs={12}>
                    <Carts />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <OrderPerMonth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ProductsPerMonth />
                </Grid>
                <Grid item xs={12} sm={6} container justifyContent="center">
                    <OrderPerStatus />
                </Grid>
                <Grid item xs={12} sm={6} container justifyContent="center">
                    <ProductsPerStatus />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Dashboard;
