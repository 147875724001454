import React, { useState, useEffect } from "react";
import { Helper } from "../../Tools/Helper";
import { api_Routes } from "../../api_Route";
import Skeleton from '@mui/material/Skeleton';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Ensure this import is included
import { Box, Typography, Paper } from '@mui/material';

export default function OrderPerMonth() {
    const [donateData, setDonateData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDonateStatistics = async () => {
        try {
            const { response } = await Helper.Get({
                url: api_Routes.statistics.ordermonth,
                hasToken: true
            });
            if (response) {
                setDonateData(response.data.series);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching donation statistics:", error);
        }
    };

    useEffect(() => {
        getDonateStatistics();
    }, []);

    // Preparing the data for the chart
    const chartData = {
        labels: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"],

        datasets: [
            {
                label: 'Orders',
                data: donateData.map(d => parseInt(d, 10)),
                backgroundColor: '#42A5F5',
                borderColor: '#1E88E5',
                borderWidth: 1,
            }
        ]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1
                }
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    };

    return (
        <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, maxWidth: 600, margin: 'auto' }}>
            <Typography variant="h6" align="center" gutterBottom>
                الطلبيات في الشهر
            </Typography>
            <Box sx={{ position: 'relative', height: 300 }}>
                {loading ? (
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                ) : (
                    <Bar data={chartData} options={options} />
                )}
            </Box>
        </Paper>
    );
}
