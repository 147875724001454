import React , {useState , useEffect , Fragment} from "react";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography , TextField  } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import { Select, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl'
import Files from 'react-files';
import { Switch } from '@mui/material';
import { useParams } from 'react-router-dom';

const EditCity = () => {
    const { cityid } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [category, setcategory] = useState([])
    const [formData, setFormData]  = useState({
        name:"",
        is_active:"",

    })
    const [dataToEditorState, setDataToEditorState] = useState({});


    useEffect(() => {

        get_city()

        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
            
                // setDataToEditorState(prev => {
                //     array.forEach((e) => {
                //         prev[e.id] = {
                //             name: { id: 0, value: "" },
                //         };
                //     });
                //     return prev;
                // });    
        }
    }, []);

    const get_city = async ()=>{
            
        const {response,message} = await Helper.Get({
            url:api_Routes.city.getOne(cityid),
            hasToken:true,
            data:{detailed:1}
        })
        if(response){
            var details_languages = {}

            console.log(response.data.translations)   
            setFormData({
                name:response.data.name,  
                is_active:response.data.is_active,  
        
            })
            response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        {
                        details_languages[dt.language_id] = {}
                        }
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
  
            setDataToEditorState(details_languages)

        }

    }

    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
              
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

     const handleSubmit = async () => {
            let exit = false

          

setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                   
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } else {
                                 exit = true
                            }
                            form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
                        })
   
            })


            Object.keys(updatedFormData).forEach((key) => {
                if (key === "photo") {
                    // Append new photo file
                    if (updatedFormData.photo instanceof File) {
                        form_data.append("file", updatedFormData.photo);
                    } 
                }
                else
                    form_data.append(key, updatedFormData[key]);
            });

              
                // form_data.append("_method", "PUT");
            
            if(exit){
                enqueueSnackbar("Please add english and Arabic name ",{variant:"error",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})
                setIsLoading(false)
                return;
            }

            const { response, message } = await Helper.Post({
                url: api_Routes.city.update(cityid) ,
                data: form_data,
                hasToken: true
            });

            if (response) {
                enqueueSnackbar(message,{variant:"success",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})       
                  navigate(`/Cities`)     
                    setIsLoading(false);
            } else {
                let errorMessage = '';
                if (typeof message === "string") {
                    errorMessage = message;
                } else if (typeof message === "object") {
                    errorMessage = Object.values(message).flat().join(', ');
                }
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                setIsLoading(false);
            }
        };  

        const [files, setFiles] = useState([]);
        
        function حذفFile(e) {
            setFiles([]);
            setFormData(prev=>({...prev,["photo"]:""}))
            return files
    
        }
        const onFilesChange = (files) => {
            setFiles(files)
            setFormData(prev=>({...prev,["photo"]:files[0]}))
            console.log(files);
        }
        const onFilesError = (error, file) => {
            setFormData(prev=>({...prev,["photo"]:""}))
            setFiles(file)
        }


        return(
            <>
                <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>تعديل المدينة</Typography>
                    </Grid>
                    <Grid item>
                    <Button 
                        variant="contained" 
                        endIcon={isLoading ? <CircularProgress sx={{color:"white"}} size={22}/> : <AddIcon sx={{marginRight:"15px"}} />} 
                        sx={{ 
                            backgroundColor: "#0A722E", 
                            fontSize: "16px", 
                            borderRadius: "7px", 
                            height: "38px",
                            '&:hover': {
                                backgroundColor: "#0A722E" // Green color on hover
                            }
                        }} 
                        onClick={handleSubmit}
                    >
                        حفظ
                    </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>المعلومات الأساسية</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className="inputlable">الاسم</InputLabel>
                                    <TextField 
                                       label="الاسم" 
                                          size="small"
                                        variant="outlined" 
                                         fullWidth
                                        name="name" 
                                        value={formData.name} 
                                        onChange={(e)=>{handleChange("name",e.target.value)}} 
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable" >نشط</InputLabel>
                                    <Switch sx={{ color: "#D80621" }} checked={formData.is_active == "1"} onChange={(e) => { handleChange("is_active", e.target.checked ? 1 : 0) }} />
                                </Grid>
                            
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
            Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container sx={{ marginBottom: "20px" }}>
                <Card>
                    <CardContent>
                        <h3>{`${lang.name} المعلومات `}</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">الاسم</InputLabel>
                                    <TextField 
                                       label="الاسم" 
                                          size="small"
                                        variant="outlined" 
                                        fullWidth 
                                        name="name" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.name.value} 
                                        onChange={(e)=>{handleChange("name",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.name.id,0)}}
                                    />
                                    {/* dataToEditorState[JSON.stringify(lang.id)].name.id */}
                                </Grid>
                                
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>))}
            </>
        )


}
export default EditCity