import React , {useState , useEffect , Fragment} from "react";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography , TextField  } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import Files from 'react-files';
import { Select, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import ReactQuill from 'react-quill';  
import 'react-quill/dist/quill.snow.css';  
import Constant from "../Constant/Constant";

const AddBanner = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [formData, setFormData]  = useState({
        name:"",
        url:"",
        description:"",
        type:"",
    })
    const [dataToEditorState, setDataToEditorState] = useState({});


    useEffect(() => {

        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
            
                setDataToEditorState(prev => {
                    array.forEach((e) => {
                        prev[e.id] = {
                            name: { id: 0, value: "" },
                            description: { id: 0, value: "" },
                        };
                    });
                    return prev;
                });    
        }
    }, []);

    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
              
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

     const handleSubmit = async () => {
           

          const requiredFields = [
       "name",
        "type",
];

const missingFields = requiredFields.filter(field => !formData[field]);

let errorMessage = "";

if (missingFields.length > 0) {
    errorMessage = `Please fill in all required fields: ${missingFields.join(", ")}. `;
}

if (!formData.photo) {
    errorMessage += `Please upload a photo.`;
}

if (errorMessage !== "") {
    enqueueSnackbar(errorMessage, {
        variant: "error",
        anchorOrigin: {
            vertical: "top",
            horizontal: "right"
        }
    });

    setIsLoading(false);
    return;
}

setIsLoading(true);


            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                   
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } 
                        form_data.append(`languages[${key}][${subKey}]`, valueTemp);
                })
   
            })


            Object.keys(updatedFormData).forEach((key) => {
                if (key === "photo")
                    form_data.append("file", updatedFormData.photo);
                else
                    form_data.append(key, updatedFormData[key]);
            });

              
                form_data.append("_method", "PUT");

            const { response, message } = await Helper.Post({
                url: api_Routes.banner.add ,
                data: form_data,
                hasToken: true
            });

            if (response) {
                enqueueSnackbar(message,{variant:"success",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})       
                  navigate(`/Banners`)     
                    setIsLoading(false);
            } else {
                let errorMessage = '';
                if (typeof message === "string") {
                    errorMessage = message;
                } else if (typeof message === "object") {
                    errorMessage = Object.values(message).flat().join(', ');
                }
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                setIsLoading(false);
            }
        };  

        const [files, setFiles] = useState([]);
        
        function deleteFile(e) {
            setFiles([]);
            setFormData(prev=>({...prev,["photo"]:""}))
            return files
    
        }
        const onFilesChange = (files) => {
            setFiles(files)
            setFormData(prev=>({...prev,["photo"]:files[0]}))
            console.log(files);
        }
        const onFilesError = (error, file) => {
            setFormData(prev=>({...prev,["photo"]:""}))
            setFiles(file)
        }


        return(
            <>
                <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Add Banner</Typography>
                    </Grid>
                    <Grid item>
                    <Button 
                        variant="contained" 
                        startIcon={isLoading ? <CircularProgress sx={{color:"white"}} size={22}/> : <AddIcon />} 
                        sx={{ 
                            backgroundColor: "#0A722E", 
                            fontSize: "13px", 
                            borderRadius: "7px", 
                            height: "38px",
                            '&:hover': {
                                backgroundColor: "#0A722E" // Green color on hover
                            }
                        }} 
                        onClick={handleSubmit}
                    >
                        حفظ
                    </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>المعلومات الأساسية</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className="inputlable">الاسم</InputLabel>
                                    <TextField 
                                      size="small"
                                       label="الاسم" 
                                        variant="outlined" 
                                         fullWidth
                                        name="name" 
                                        value={formData.name} 
                                        onChange={(e)=>{handleChange("name",e.target.value)}} 
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">الرابط</InputLabel>
                                    <TextField 
                                      size="small"
                                        label="الرابط" 
                                        variant="outlined" 
                                        fullWidth
                                        name="origin" 
                                        value={formData.url} 
                                        onChange={(e)=>{handleChange("url",e.target.value)}} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">الوصف</InputLabel>
                                    <TextField 
                                      size="small"
                                        label="الوصف" 
                                        variant="outlined" 
                                        fullWidth
                                        name="origin" 
                                        value={formData.description} 
                                        onChange={(e)=>{handleChange("description",e.target.value)}} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">النوع </InputLabel>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label" >type </InputLabel>
                                        <Select
                                            size="small"
                                            labelId="role-select-label"
                                            value={formData.type}
                                            label="النوع"
                                            onChange={(e) => { handleChange("type", e.target.value) }}
                                            sx={{ width: { xs: "100%", sm: "90%", md: "90%", lg: "152%" }, }}
                                        >
                                            <MenuItem value="1">Main Banner</MenuItem>
                                            <MenuItem value="2">Side Banner</MenuItem>
                                            <MenuItem value="3">Bottom Banner</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
            Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container sx={{ marginBottom: "20px" }}>
                <Card>
                    <CardContent>
                        <h3>{`${lang.name} المعلومات `}</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                <InputLabel className="inputlable">الاسم</InputLabel>
                                    <TextField 
                                      size="small"
                                       label="الاسم" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="name" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.name.value} 
                                        onChange={(e)=>{handleChange("name",e.target.value,lang.id,null,0)}}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <InputLabel className="inputlable">الوصف</InputLabel>
                                    <ReactQuill
                                        theme="snow"
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.description.value || ""}
                                        onChange={(value) => { handleChange("description", value, lang.id, null, 1) }}
                                        style={{ height: '150px',marginBottom:"40px" }}
                                        modules={Constant}
                                    />
                                </Grid>
                                
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>))}
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>رفع صورة</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                        <Files
                                        className='files-dropzone fileContainer'
                                        onChange={onFilesChange}
                                        onError={onFilesError}
                                        accepts={['image/*']}
                                        multiple={false}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                        >
                                                {
                                                    files.length > 0
                                                        ? <div style={{textAlign:"center"}}>
                                                            {files.map((file, index) =>
                                                                <div key={index}>
                                                                    <img width="400px" height="200px"  alt= "img" src= {`${file.preview.url}`}  />
                                                                </div>
                                                            )}
                                                        </div>

                                                        : <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                                                            <Button sx={{backgroundColor:"#0A722E",color:"white",padding:"10px",'&:hover': {  backgroundColor: "#0A722E",color:"white"  }}} >رفع صورة</Button>
                                                        </div>
                                                }
                                        </Files>
                                            {files.length > 0 ?
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                                                    <Button  onClick={() => deleteFile(files)} sx={{backgroundColor:"red",color:"white",padding:"8px 16px",'&:hover': {  backgroundColor: "red",color:"white"  }}}    >
                                                        Delete
                                                    </Button>
                                                </div> : ''
                                            }
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            </>
        )


}
export default AddBanner