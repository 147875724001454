import React, { useState, useEffect , useRef } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
  Typography,
  TextField,
  Container,
  Card,
  CardContent,
  Box,
  Grid,
  Button,
  Divider,
  Paper,
  Stack,
} from "@mui/material";
import { AccountCircle, LocationOn, MonetizationOn, ReceiptLong  } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const DetailsOrder = () => {
  const { orderid } = useParams();
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({});
  const [boy, setboy] = useState([]);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);
  const controllerRef = useRef(null)
  const [formData2, setFormData2] = useState({
        delivery_boy_id:""
    })

  useEffect(() => {
    get_order();
    get_deliveryBoy()
  }, []);

  const get_order = async () => {
    const { response, message } = await Helper.Get({
      url: api_Routes.order.getOne(orderid),
      hasToken: true,
    });
    if (response) {
      setFormData(response.data);
    }
  };

  const handleChange = (key, value) => {
    setFormData2(prev => ({ ...prev, [key]: value }));
  };

  const get_deliveryBoy = async (keyword) => {
    setIsLoadingRoles(true);

    if(controllerRef.current){
        controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    const { response, message } = await Helper.Get_Abort({
        url: api_Routes.delivery_boy.view,
        signal:signal,
        data:{name:keyword},
        hasToken: true,
    });
    if (response) {
        setboy([]);
        response.data.forEach(ele => {
            setboy(prev => [...prev, {
                label: ele.name,
                value: ele.id
            }]);
        });
        setIsLoadingRoles(false);
    } else {
        console.log(message);
        setIsLoadingRoles(false);
    }
  };

  const handleSubmit = async () => {
    const { response, message } = await Helper.Post({
        url: api_Routes.order.update(orderid),
        data:{delivery_boy_id:formData2.delivery_boy_id},
        hasToken: true
    });

    if (response) {
        enqueueSnackbar(message, {
            variant: "success", anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
        navigate(`/Orders`);
    } else {
        let errorMessage = '';
        if (typeof message === "string") {
            errorMessage = message;
        } else if (typeof message === "object") {
            errorMessage = Object.values(message).flat().join(', ');
        }
        enqueueSnackbar(errorMessage, {
            variant: "error",
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }
  };
  const handleSubmitStatus = async (status) => {
    const { response, message } = await Helper.Post({
        url: api_Routes.order.update(orderid),
        data:{status:status},
        hasToken: true
    });

    if (response) {
        enqueueSnackbar(message, {
            variant: "success", anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
        get_order()
    } else {
        let errorMessage = '';
        if (typeof message === "string") {
            errorMessage = message;
        } else if (typeof message === "object") {
            errorMessage = Object.values(message).flat().join(', ');
        }
        enqueueSnackbar(errorMessage, {
            variant: "error",
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }
  };

  return (
    
    <Container maxWidth="lg" sx={{ mt: 4 }}>
          <CardContent>
            <Typography variant="h4" align="center" color="#0A722E" gutterBottom>
              تفاصيل الطلب
            </Typography>
            <Divider sx={{ mb: 4 }} />
            <Grid container spacing={3} alignItems="stretch">
          {formData.status === 1 &&
            <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 , height:"55%" }}>
                  <Stack direction="row" alignItems="center" mb={2}>
                    <ReceiptLong color="#0A722E" sx={{ ml: 1 }} />
                    <Typography variant="h6" color="#0A722E">حالة الطلب</Typography>
                  </Stack>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"18px"} ><strong>  الحالة   :   </strong> معلق</Typography>
                    <Button variant="contained" color="success"  sx={{ height: '37px',fontSize:"17px"  }} onClick={()=>handleSubmitStatus(2)}>
                        قبول
                      </Button>
                      <Button variant="contained" color="error"   sx={{ height: '37px' ,fontSize:"17px"  }} onClick={()=>handleSubmitStatus(4)}>
                        رفض
                      </Button>
                  </Box>
                </Paper>
              </Grid>
          }
              
              {(formData.delivery_boy_id == 0 && formData.status == 2 ) && (
              <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                  <Stack direction="row" alignItems="center" mb={3}>
                    <AccountCircle color="#0A722E" sx={{ ml: 1 }} />
                    <Typography variant="h6" color="#0A722E">تعيين موظف التوصيل</Typography>
                  </Stack>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={7}>
                      <Autocomplete
                        options={boy}
                        getOptionLabel={(option) => option.label}
                        value={boy.find((r) => r.value === formData2.delivery_boy_id) || null}
                        onChange={(event, newValue) => handleChange("delivery_boy_id", newValue ? newValue.value : '')}
                        onInputChange={(event, newInputValue) => get_deliveryBoy(newInputValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="اختر موظف التوصيل"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isLoadingRoles && <CircularProgress color="inherit" size={20} />}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                     
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button variant="contained" fullWidth sx={{ height: '37px' ,backgroundColor:"#0A722E" }} onClick={handleSubmit}>
                        تعيين
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              )}

              {/* Order Details */}
              <Grid item xs={12} md={6}>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 , height:"80%" }}>
                  <Stack direction="row" alignItems="center" mb={2}>
                    <ReceiptLong color="#0A722E" sx={{ ml: 1 }} />
                    <Typography variant="h6" color="#0A722E">معلومات الطلب</Typography>
                  </Stack>
                  <Typography mb={1}><strong>معرف الطلب:</strong> {formData.id}</Typography>
                  <Typography mb={1}><strong>الحالة:</strong> {formData.order_status_name || 'N/A'}</Typography>
                  <Typography mb={1}><strong>وقت الإنشاء:</strong> {new Date(formData.created_at).toLocaleString()}</Typography>
                  <Typography mb={1}><strong>الدفع:</strong> {formData.paid ? 'نعم' : 'لا'}</Typography>
                </Paper>
              </Grid>

              {/* Delivery Info */}
              <Grid item xs={12} md={6}>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 , height:"80%" }}>
                  <Stack direction="row" alignItems="center" mb={2}>
                    <LocationOn color="#0A722E" sx={{ ml: 1 }} />
                    <Typography variant="h6" color="#0A722E">معلومات التوصيل</Typography>
                  </Stack>
                  <Typography mb={1}><strong>موظف التوصيل:</strong> {formData.delivery_boy_name || 'لم يتم التعيين'}</Typography>
                  <Typography mb={1}><strong>رقم الهاتف:</strong> {formData.delivery_boy_phone || 'لم يتم التعيين'}</Typography>
                  <Typography mb={1}><strong>العنوان:</strong> {formData.street}, {formData.building}, Floor: {formData.floor}</Typography>
                  <Typography mb={1}>{formData.area}</Typography>
                </Paper>
              </Grid>

              {/* Pricing Details */}
              <Grid item xs={12} md={6}>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 , height:"80%" }}>
                  <Stack direction="row" alignItems="center" mb={2}>
                    <MonetizationOn color="#0A722E" sx={{ ml: 1 }} />
                    <Typography variant="h6" color="#0A722E">تفاصيل الأسعار</Typography>
                  </Stack>
                  <Typography mb={1}><strong>السعر:</strong> ${formData.total}</Typography>
                  <Typography mb={1}><strong>الخصم:</strong> ${formData.discount}</Typography>
                  <Typography mb={1}><strong>السعر النهائي:</strong> ${formData.price}</Typography>
                </Paper>
              </Grid>

              {/* User Info */}
              <Grid item xs={12} md={6}>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 , height:"80%" }}>
                  <Stack direction="row" alignItems="center" mb={2}>
                    <AccountCircle color="#0A722E" sx={{ ml: 1 }} />
                    <Typography variant="h6" color="#0A722E">معلومات المستخدم</Typography>
                  </Stack>
                  <Typography mb={1}><strong>معرف المستخدم:</strong> {formData.user_id}</Typography>
                  <Typography mb={1}><strong>اسم المستخدم:</strong> {formData.username || 'N/A'}</Typography>
                </Paper>
              </Grid>

              {/* Product Info */}
              <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2  }}>
                  <Stack direction="row" alignItems="center" mb={2}>
                    <ProductionQuantityLimitsIcon color="#0A722E" sx={{ ml: 1 }} />
                    <Typography variant="h6" color="#0A722E">معلومات المنتجات</Typography>
                  </Stack>
                  <TableContainer >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ color: "#0A722E !important" }}><strong>المعرف</strong></TableCell>
                          <TableCell sx={{ color: "#0A722E !important" }}><strong>الصورة</strong></TableCell>
                          <TableCell sx={{ color: "#0A722E !important" }}><strong>الاسم</strong></TableCell>
                          <TableCell sx={{ color: "#0A722E !important" }}><strong>السعر</strong></TableCell>
                          <TableCell sx={{ color: "#0A722E !important" }}><strong>العدد</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formData.items && formData.items.map((e, index) => (
                          <TableRow key={index}>
                            <TableCell>{e.product_id}</TableCell>
                            <TableCell><img style={{ width: "50px", height: "50px", borderRadius: "8px" }} src={e.product_photo} alt={e.translations["en"].name} /></TableCell>
                            <TableCell>{e.translations["ar"].name}</TableCell>
                            <TableCell>{e.price} SYP</TableCell>
                            <TableCell>{e.quantity}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
    </Container>
  );
};

export default DetailsOrder;
