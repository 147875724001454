import React , {useState , useEffect , Fragment} from "react";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography , TextField  } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import Files from 'react-files';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'; 
import ReactQuill from 'react-quill';  
import 'react-quill/dist/quill.snow.css';  
import Constant from "../Constant/Constant"; 

const EditBlog = () => {
    const { blogid } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [breed, setbreed] = useState([])
    const [formData, setFormData]  = useState({
        name:"",
        description:"",
        photos: [],
        delete_photo_id:[],
    })
    const [dataToEditorState, setDataToEditorState] = useState({});


    useEffect(() => {

        get_blog()

        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
            
                // setDataToEditorState(prev => {
                //     array.forEach((e) => {
                //         prev[e.id] = {
                //             name: { id: 0, value: "" },
                //             description: { id: 0, value: "" },
                //         };
                //     });
                //     return prev;
                // });    
        }
    }, []);

    const get_blog = async ()=>{
            
        const {response,message} = await Helper.Get({
            url:api_Routes.blog.getOne(blogid),
            hasToken:true,
            data:{detailed:1}
        })
        if(response){
            var details_languages = {}

            console.log(response.data.translations)   
            setFormData({
                name:response.data.name,  
                description:response.data.description,   
                photos:response.data.photos
            })
            response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        {
                        details_languages[dt.language_id] = {}
                        }
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
            setFiles(response.data.photo?[{
                preview: {type: 'image', url: response.data.photo}
            }]:"")
            setDataToEditorState(details_languages)

        }

    }

    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
              
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

     const handleSubmit = async () => {
            let exit = false

          
          const requiredFields = [
       "name",
        "description",
        ];

        const missingFields = requiredFields.filter(field => !formData[field]);

        let errorMessage = "";

        if (missingFields.length > 0) {
            errorMessage = `Please fill in all required fields: ${missingFields.join(", ")}. `;
        }

        if (errorMessage !== "") {
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right"
                }
            });

            setIsLoading(false);
            return;
        }

        setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                   
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } else {
                                 exit = true
                            }
                            form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
                        })
   
            })


            Object.keys(updatedFormData).forEach((key) => {
                if (key === "photo") {
                    // Append new photo file
                    if (updatedFormData.photo instanceof File) {
                        form_data.append("file", updatedFormData.photo);
                    } 
                } else if (key === "photos") {
                    // Append new photos
                    updatedFormData.photos.forEach((file, index) => {
                        if (file instanceof File) {
                            form_data.append(`photos[]`, file); // Append each photo individually
                        } else {
                            // If file is not an instance of File (e.g., URL), you might want to handle it differently
                            console.log('Photo is not a file object:', file);
                        }
                    });
                    
                } 
                else if (key === "delete_photo_id") {
                    updatedFormData?.delete_photo_id?.forEach((id) => {
                        form_data.append("deleted_photos[]", id);
                    });
                }
                else {
                    form_data.append(key, updatedFormData[key]);
                }
            });


              
                // form_data.append("_method", "PUT");
            
            if(exit){
                enqueueSnackbar("Please add english and Arabic name ",{variant:"error",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})
                setIsLoading(false)
                return;
            }

            const { response, message } = await Helper.Post({
                url: api_Routes.blog.update(blogid) ,
                data: form_data,
                hasToken: true
            });

            if (response) {
                enqueueSnackbar(message,{variant:"success",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})       
                  navigate(`/blogs`)     
                    setIsLoading(false);
            } else {
                let errorMessage = '';
                if (typeof message === "string") {
                    errorMessage = message;
                } else if (typeof message === "object") {
                    errorMessage = Object.values(message).flat().join(', ');
                }
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                setIsLoading(false);
            }
        };  

        const [files, setFiles] = useState([]);
        
        function deleteFile(e) {
            setFiles([]);
            setFormData(prev=>({...prev,["photo"]:""}))
            return files
    
        }
        const onFilesChange = (files) => {
            setFiles(files)
            setFormData(prev=>({...prev,["photo"]:files[0]}))
            console.log(files);
        }
        const onFilesError = (error, file) => {
            setFormData(prev=>({...prev,["photo"]:""}))
            setFiles(file)
        }

        const onDrop = (acceptedFiles) => {  
            const filesWithPreview = acceptedFiles.map(file => Object.assign(file, {  
                url: URL.createObjectURL(file)  
            }));  
            setFormData(prev => ({ ...prev, photos: [...prev.photos, ...filesWithPreview] })); 
        };  
        
        const deleteFiles = (fileToDelete,id) => {  

            setFormData(prev => ({
                ...prev,
                photos: prev.photos.filter(file => file !== fileToDelete),
                delete_photo_id: id != (undefined) ? [...(prev.delete_photo_id || []), id] : prev.delete_photo_id
            }));  
        };

    
        const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });  
    


        return(
            <>
                <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>تعديل المدونة</Typography>
                    </Grid>
                    <Grid item>
                    <Button 
                        variant="contained" 
                        endIcon={isLoading ? <CircularProgress sx={{color:"white"}} size={22}/> : <AddIcon sx={{marginRight:"15px"}} />}                        sx={{ 
                            backgroundColor: "#0A722E", 
                            fontSize: "16px", 
                            borderRadius: "7px", 
                            height: "38px",
                            '&:hover': {
                                backgroundColor: "#0A722E" // Green color on hover
                            }
                        }} 
                        onClick={handleSubmit}
                    >
                        حفظ
                    </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>المعلومات الأساسية</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className="inputlable">الاسم</InputLabel>
                                    <TextField 
                                       label="الاسم" 
                                          size="small"
                                        variant="outlined" 
                                         fullWidth
                                        name="name" 
                                        value={formData.name} 
                                        onChange={(e)=>{handleChange("name",e.target.value)}} 
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">الوصف</InputLabel>
                                    <TextField 
                                        label="الوصف" 
                                          size="small"
                                        variant="outlined" 
                                        fullWidth
                                        name="origin" 
                                        value={formData.description} 
                                        onChange={(e)=>{handleChange("description",e.target.value)}} 
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
            Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container sx={{ marginBottom: "20px" }}>
                <Card>
                    <CardContent>
                        <h3>{`${lang.name} المعلومات `}</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                <InputLabel className="inputlable">الاسم</InputLabel>
                                    <TextField 
                                       label="الاسم" 
                                          size="small"
                                        variant="outlined" 
                                        fullWidth 
                                        name="name" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.name?.value} 
                                        onChange={(e)=>{handleChange("name",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.name.id,0)}}
                                    />
                                    {/* dataToEditorState[JSON.stringify(lang.id)].name.id */}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <InputLabel className="inputlable">الوصف</InputLabel>
                                    <ReactQuill 
                                        theme="snow"
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.description?.value } 
                                        onChange={(e)=>{handleChange("description",e,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.description.id,1)}}  
                                        style={{ height: '150px',marginBottom:"40px" }}
                                        modules={Constant}

                                   />
                                </Grid>
                                
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>))}
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>رفع صورة</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                        <Files
                                        className='files-dropzone fileContainer'
                                        onChange={onFilesChange}
                                        onError={onFilesError}
                                        accepts={['image/*']}
                                        multiple={false}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                        >
                                                {
                                                    files.length > 0
                                                        ? <div style={{textAlign:"center"}}>
                                                            {files.map((file, index) =>
                                                                <div key={index}>
                                                                    <img width="400px" height="200px"  alt= "img" src= {`${file.preview.url}`}  />
                                                                </div>
                                                            )}
                                                        </div>

                                                        : <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                                                            <Button sx={{backgroundColor:"#0A722E",color:"white",padding:"10px",'&:hover': {  backgroundColor: "#0A722E",color:"white"  }}} >رفع صورة</Button>
                                                        </div>
                                                }
                                        </Files>
                                            {files.length > 0 ?
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                                                    <Button  onClick={() => deleteFile(files)} sx={{backgroundColor:"red",color:"white",padding:"8px 16px",'&:hover': {  backgroundColor: "red",color:"white"  }}}    >
                                                        Delete
                                                    </Button>
                                                </div> : ''
                                            }
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            <Container sx={{ marginBottom: "50px" }}>
                <Card style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>  
                    <CardContent>
                      <h3 style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>رفع صور</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                <div {...getRootProps({ className: 'dropzone' })}>  
                                    <input {...getInputProps()} />  
                                    <p>اسحب وافلت بعض الصور هنا او قم بالاختيار من المعرض</p>  
                                    <Button sx={{ backgroundColor: "#0A722E", color: "white", marginTop: '10px' }}>رفع صورة</Button>  
                                    {formData.photos.length > 0 && (  
                                         <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap' }}>   
                                            {formData.photos.map((file, index) => (  
                                                <div key={index} style={{ margin: '5px', width: '10%', position: 'relative' }}>  
                                                    <img src={file.url} alt="preview" style={{ width: '100px',height:"100px", borderRadius: '4px',maxWidth:"100%" }} />  
                                                    <button onClick={() => deleteFiles(file,file.id)}
                                                      style={{  
                                                        position: 'absolute',   
                                                        top: '5px',   
                                                        right: '5px',   
                                                        fontSize: '12px',  
                                                        backgroundColor: '#f44336',   
                                                        border:"1px solid white",
                                                        borderRadius:"6px",
                                                        color: 'white',
                                                        cursor:"pointer"  
                                                    }}>  
                                                        x  
                                                    </button>  
                                                </div>  
                                            ))}  
                                        </div>  
                                    )}  
                                </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            </>
        )


}
export default EditBlog