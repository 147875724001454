import React , {useState , useEffect , Fragment , useRef} from "react";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography , TextField  } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import { Select, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl'
import Files from 'react-files';
import { useParams } from 'react-router-dom';
import { Autocomplete } from '@mui/material';
import { Switch } from '@mui/material';
import ReactQuill from 'react-quill';  
import 'react-quill/dist/quill.snow.css';  
import Constant from "../Constant/Constant";

const EditCategory = () => {
    const { categoryid } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const controllerRef = useRef(null)
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingRoles, setIsLoadingRoles] = useState(false); // New state for role loading
    const [langauges, setLangauges] = useState([])
    const [category, setcategory] = useState([])
    const [formData, setFormData]  = useState({
        name:"",
        parent:"",
        featured:""
    })
    const [dataToEditorState, setDataToEditorState] = useState({});


    useEffect(() => {

        get_category()

        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
            
                // setDataToEditorState(prev => {
                //     array.forEach((e) => {
                //         prev[e.id] = {
                //             name: { id: 0, value: "" },
                //             // description: { id: 0, value: "" },
                //         };
                //     });
                //     return prev;
                // });    
        }
    }, []);

    const get_category_parent = async (keyword) => {
        setIsLoadingRoles(true); 

        if(controllerRef.current){
            controllerRef.current.abort()
        }
        controllerRef.current = new AbortController()
        const signal = controllerRef.current.signal

        const { response, message } = await Helper.Get_Abort({
            url: api_Routes.category.view,
            signal:signal,
            data:{keywords:keyword},
            hasToken: true,
        })
        if (response) {
            setcategory([])
            response.data.forEach(ele => {
                setcategory(prev => [...prev, {
                    label: ele.translations["en"].name,
                    value: ele.id
                }])
            })
            setIsLoadingRoles(false);
        } else {
            console.log(message);
        }
    }

    const get_category = async ()=>{
            
        const {response,message} = await Helper.Get({
            url:api_Routes.category.getOne(categoryid),
            hasToken:true,
            data:{detailed:1}
        })
        if(response){
            var details_languages = {}

            console.log(response.data.translations)   
            setFormData({
                name:response.data.name,  
                photo:response.data.photo,  
                featured:response.data.featured,
                parent:response.data.parent                         
            })
            get_category_parent(response.data.parent_name)
            response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        {
                        details_languages[dt.language_id] = {}
                        }
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
            setFiles(response.data.photo?[{
                preview: {type: 'image', url: response.data.photo}
            }]:"")
            setDataToEditorState(details_languages)

        }

    }

    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
              
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

     const handleSubmit = async () => {
          

          

setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                   
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } 
                            form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
                        })
   
            })


            Object.keys(updatedFormData).forEach((key) => {
                if (key === "photo") {
                    // Append new photo file
                    if (updatedFormData.photo instanceof File) {
                        form_data.append("file", updatedFormData.photo);
                    } 
                }
                else
                    form_data.append(key, updatedFormData[key]);
            });


            const { response, message } = await Helper.Post({
                url: api_Routes.category.update(categoryid) ,
                data: form_data,
                hasToken: true
            });

            if (response) {
                enqueueSnackbar(message,{variant:"success",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})       
                  navigate(`/categories`)     
                    setIsLoading(false);
            } else {
                let errorMessage = '';
                if (typeof message === "string") {
                    errorMessage = message;
                } else if (typeof message === "object") {
                    errorMessage = Object.values(message).flat().join(', ');
                }
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                setIsLoading(false);
            }
        };  

        const [files, setFiles] = useState([]);
        
        function deleteFile(e) {
            setFiles([]);
            setFormData(prev=>({...prev,["photo"]:""}))
            return files
    
        }
        const onFilesChange = (files) => {
            setFiles(files)
            setFormData(prev=>({...prev,["photo"]:files[0]}))
            console.log(files);
        }
        const onFilesError = (error, file) => {
            setFormData(prev=>({...prev,["photo"]:""}))
            setFiles(file)
        }


        return(
            <>
                <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>تعديل الصنف</Typography>
                    </Grid>
                    <Grid item>
                    <Button 
                        variant="contained" 
                        endIcon={isLoading ? <CircularProgress sx={{color:"white"}} size={22}/> : <AddIcon sx={{marginRight:"15px"}} />}
                        sx={{ 
                            backgroundColor: "#0A722E", 
                            fontSize: "16px", 
                            borderRadius: "7px", 
                            height: "38px",
                            '&:hover': {
                                backgroundColor: "#0A722E" // Green color on hover
                            }
                        }} 
                        onClick={handleSubmit}
                    >
                        حفظ
                    </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>المعلومات الأساسية</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">الاسم</InputLabel>
                                    <TextField 
                                       label="الاسم" 
                                          size="small"
                                        variant="outlined" 
                                         fullWidth
                                        name="name" 
                                        value={formData.name} 
                                        onChange={(e)=>{handleChange("name",e.target.value)}} 
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">الأب</InputLabel>
                                    <FormControl sx={{ width: "100%" }}>
                                        <Autocomplete
                                            options={category}
                                              size="small"
                                            getOptionLabel={(option) => option.label}
                                            value={category.find((r) => r.value === formData.parent) || null}
                                            onChange={(event, newValue) => {
                                                handleChange("parent", newValue ? newValue.value : '');
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                get_category_parent(newInputValue); // Fetch roles based on input
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="الأب"
                                                    sx={{ width: { xs: "100%", sm: "90%", md: "90%", lg: "100%" } }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {isLoadingRoles ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable" >مميز</InputLabel>
                                    <Switch sx={{ color: "#D80621" }} checked={formData.featured == "1"} onChange={(e) => { handleChange("featured", e.target.checked ? 1 : 0) }} />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
            Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container sx={{ marginBottom: "20px" }}>
                <Card>
                    <CardContent>
                        <h3>{`${lang.name} المعلومات `}</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                <InputLabel className="inputlable">الاسم</InputLabel>
                                    <TextField 
                                       label="الاسم" 
                                          size="small"
                                        variant="outlined" 
                                        fullWidth 
                                        name="name" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.name.value} 
                                        onChange={(e)=>{handleChange("name",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.name.id,0)}}
                                    />
                                    {/* dataToEditorState[JSON.stringify(lang.id)].name.id */}
                                </Grid>
                                {/* <Grid item xs={12} sm={12}>
                                <InputLabel className="inputlable">الوصف</InputLabel>
                                    <ReactQuill 
                                        theme="snow"
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.description?.value } 
                                        onChange={(e)=>{handleChange("description",e,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.description.id,1)}}  
                                        style={{ height: '150px',marginBottom:"40px" }}
                                        modules={Constant}

                                   />
                                </Grid> */}
                                
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>))}
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>رفع صورة</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                        <Files
                                        className='files-dropzone fileContainer'
                                        onChange={onFilesChange}
                                        onError={onFilesError}
                                        accepts={['image/*']}
                                        multiple={false}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                        >
                                                {
                                                    files.length > 0
                                                        ? <div style={{textAlign:"center"}}>
                                                            {files.map((file, index) =>
                                                                <div key={index}>
                                                                    <img width="400px" height="200px"  alt= "img" src= {`${file.preview.url}`}  />
                                                                </div>
                                                            )}
                                                        </div>

                                                        : <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                                                            <Button sx={{backgroundColor:"#0A722E",color:"white",padding:"10px",'&:hover': {  backgroundColor: "#0A722E",color:"white"  }}} >رفع صورة</Button>
                                                        </div>
                                                }
                                        </Files>
                                            {files.length > 0 ?
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                                                    <Button  onClick={() => deleteFile(files)} sx={{backgroundColor:"red",color:"white",padding:"8px 16px",'&:hover': {  backgroundColor: "red",color:"white"  }}}    >
                                                        Delete
                                                    </Button>
                                                </div> : ''
                                            }
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            </>
        )


}
export default EditCategory