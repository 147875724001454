import React, { useState, useEffect } from "react";
import { Helper } from "../../Tools/Helper";
import { api_Routes } from "../../api_Route";
import Skeleton from '@mui/material/Skeleton';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto'; // Ensure this import is included
import { Box, Typography, Paper } from '@mui/material';

export default function ProductsPerStatus() {
    const [statusData, setStatusData] = useState([]);
    const [statusLabels, setStatusLabels] = useState([]);
    const [loading, setLoading] = useState(true);

    const getProductStatusStatistics = async () => {
        try {
            const { response } = await Helper.Get({
                url: api_Routes.statistics.productstatus,
                hasToken: true
            });
            if (response) {
                setStatusData(response.data.series);
                setStatusLabels(response.data.options);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching order status statistics:", error);
        }
    };

    useEffect(() => {
        getProductStatusStatistics();
    }, []);

    // Preparing the data for the chart
    const chartData = {
        labels: statusLabels,
        datasets: [
            {
                data: statusData,
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#E57373', '#81C784'],
                borderColor: '#fff',
                borderWidth: 1,
            }
        ]
    };

    const options = {
        plugins: {
            legend: {
                position: 'bottom'
            }
        }
    };

    return (
        <Paper elevation={3} sx={{ padding: 2, borderRadius: 2}}>
            <Typography variant="h6" align="center" gutterBottom>
                المنتجات حسب الحالة
            </Typography>
            <Box sx={{ position: 'relative', height: 300 }}>
                {loading ? (
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                ) : (
                    <Pie data={chartData} options={options} height={300} width={300} />
                )}
            </Box>
        </Paper>
    );
}
