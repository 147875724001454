import React , {useState , useEffect , Fragment} from "react";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography , TextField  } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import Files from 'react-files';
import { useParams } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import ReactQuill from 'react-quill';  
import 'react-quill/dist/quill.snow.css';  
import Constant from "../Constant/Constant";

const EditVendor = () => {
    const { vendorid } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingApprove, setIsLoadingApprove] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [breed, setbreed] = useState([])
    const [formData, setFormData]  = useState({
        name:"",
        phone:"",
        email:"",
    })
    const [formDataAppApprove, setFormDataAppApprove]  = useState({
        name:"",
        username:"",
        email:"",
        password:""
     })
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [coverPhoto, setCoverPhoto] = useState(null);
    const [isApproved, setisApproved] = useState(null);



    useEffect(() => {

        get_banner()

        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
  
        }
    }, []);

    const get_banner = async ()=>{
            
        const {response,message} = await Helper.Get({
            url:api_Routes.vendor.getOne(vendorid),
            hasToken:true,
            data:{detailed:1}
        })
        if(response){
            var details_languages = {}
            setisApproved(response.data.approved)

            console.log(response.data.translations)   
            setFormData({
                name:response.data.name,  
                email:response.data.email,   
                phone:response.data.phone,   
                photo:response.data.photo,                            
            })
            response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        {
                        details_languages[dt.language_id] = {}
                        }
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
            setFiles(response.data.photo?[{
                preview: {type: 'image', url: response.data.photo}
            }]:"")
            setCoverPhoto(response.data.cover?[{
                preview: {type: 'image', url: response.data.cover}
            }]:"")
            setDataToEditorState(details_languages)

        }

    }

    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
              
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };
    const handleChangeApprove = (key, value) => {
             setFormDataAppApprove(prev => ({ ...prev, [key]: value }));
        };

     const handleSubmit = async () => {
          

    const requiredFields = [
       "name",
    ];

const missingFields = requiredFields.filter(field => !formData[field]);

let errorMessage = "";

if (missingFields.length > 0) {
    errorMessage = `Please fill in all required fields: ${missingFields.join(", ")}. `;
}

if (!formData.photo) {
    errorMessage += `Please upload a photo.`;
}

if (errorMessage !== "") {
    enqueueSnackbar(errorMessage, {
        variant: "error",
        anchorOrigin: {
            vertical: "top",
            horizontal: "right"
        }
    });

    setIsLoading(false);
    return;
}

setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                   
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            }
                            form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
                        })
   
            })


            Object.keys(updatedFormData).forEach((key) => {
                if (key === "photo"){
                    if (updatedFormData.photo instanceof File) {
                        form_data.append("photo_file", updatedFormData.photo);
                    } 
                }
                else if (key === "cover_photo"){
                    if (updatedFormData.photo instanceof File) {
                        form_data.append("cover_file", updatedFormData.photo);
                    } 
                }
                else
                    form_data.append(key, updatedFormData[key]);
            });

              
                // form_data.append("_method", "PUT");
            
        

            const { response, message } = await Helper.Post({
                url: api_Routes.vendor.update(vendorid) ,
                data: form_data,
                hasToken: true
            });

            if (response) {
                enqueueSnackbar(message,{variant:"success",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})       
                  navigate(`/Vendors`)     
                    setIsLoading(false);
            } else {
                let errorMessage = '';
                if (typeof message === "string") {
                    errorMessage = message;
                } else if (typeof message === "object") {
                    errorMessage = Object.values(message).flat().join(', ');
                }
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                setIsLoading(false);
            }
        };  
    const handleApprove = async () => {

        setIsLoadingApprove(true)
          

    const requiredFields = [
       "name",
    ];

        const missingFields = requiredFields.filter(field => !formData[field]);

        let errorMessage = "";

        if (missingFields.length > 0) {
            errorMessage = `Please fill in all required fields: ${missingFields.join(", ")}. `;
        }

        if (errorMessage !== "") {
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right"
                }
            });

            setIsLoadingApprove(false);
            return;
        }


            var form_data = new FormData();
            var updatedFormData = { ...formDataAppApprove };
            var valueTemp = ''


            Object.keys(updatedFormData).forEach((key) => {
                
                    form_data.append(key, updatedFormData[key]);
            });

              
                // form_data.append("_method", "PUT");
            
        

            const { response, message } = await Helper.Post({
                url: api_Routes.vendor.approve(vendorid) ,
                data: form_data,
                hasToken: true
            });

            if (response) {
                enqueueSnackbar(message,{variant:"success",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})       
                  navigate(`/Vendors`)   
                  setIsLoadingApprove(false)  
            } else {
                let errorMessage = '';
                if (typeof message === "string") {
                    errorMessage = message;
                } else if (typeof message === "object") {
                    errorMessage = Object.values(message).flat().join(', ');
                }
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                setIsLoadingApprove(false)
            }
        };  

        const [files, setFiles] = useState([]);
        
        function deleteFile(e) {
            setFiles([]);
            setFormData(prev=>({...prev,["photo"]:""}))
            return files
    
        }
        const onFilesChange = (files) => {
            setFiles(files)
            setFormData(prev=>({...prev,["photo"]:files[0]}))
            console.log(files);
        }
        const onFilesError = (error, file) => {
            setFormData(prev=>({...prev,["photo"]:""}))
            setFiles(file)
        }
        const onCoverFilesChange = (files) => {
            setCoverPhoto(files[0]);
            setFormData(prev => ({ ...prev, ["cover_photo"]: files[0] }));
        };
        
        const onCoverFilesError = (error, file) => {
            setFormData(prev => ({ ...prev, ["cover_photo"]: "" }));
            setCoverPhoto(file);
        };
        function deleteFilecover(e) {
            setFiles([]);
            setFormData(prev => ({ ...prev, ["cover_photo"]: "" }))
            return files
    
        }

        return(
            <>
            {isApproved === 0 ?
            <Container sx={{ marginBottom: "50px" }}>            
                <Card>
                    <CardContent>
                        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <h3> تنشيط المورد</h3>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#0A722E", // Primary green background
                                    color: "white", // Text color
                                    fontSize: "16px", // Font size
                                    borderRadius: "8px", // Rounded corners
                                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Slight shadow for depth
                                    '&:hover': {
                                        backgroundColor: "#085A24", // Darker green on hover
                                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)", // Deeper shadow on hover
                                    },
                                    transition: "all 0.3s ease-in-out", // Smooth transition for hover
                                }}
                                onClick={handleApprove}
                            >
                                تنشيط
                            </Button>
                        </Box>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className="inputlable">الاسم</InputLabel>
                                    <TextField 
                                      size="small"
                                       label="الاسم" 
                                        variant="outlined" 
                                         fullWidth
                                        name="name" 
                                        value={formDataAppApprove.name} 
                                        onChange={(e)=>{handleChangeApprove("name",e.target.value)}} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className="inputlable">الايميل </InputLabel>
                                    <TextField
                                        label="الايميل"
                                        size="small"
                                        fullWidth

                                        variant="outlined"
                                        value={formDataAppApprove.email}
                                        onChange={(e) => { handleChangeApprove("email", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className="inputlable"> اسم المستخدم</InputLabel>
                                    <TextField
                                        label=" اسم المستخدم"
                                        size="small"
                                        fullWidth

                                        variant="outlined"
                                        value={formDataAppApprove.username}
                                        onChange={(e) => { handleChangeApprove("username", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className="inputlable">  كلمة المرور</InputLabel>
                                    <TextField
                                        label=" كلمة المرور"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        type="number"
                                        value={formDataAppApprove.password}
                                        onChange={(e) => { handleChangeApprove("password", e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            : ""
            }
                <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>تعديل المورد</Typography>
                    </Grid>
                    <Grid item>
                    <Button 
                        variant="contained" 
                        endIcon={isLoading ? <CircularProgress sx={{color:"white"}} size={22}/> : <AddIcon sx={{marginRight:"15px"}} />}
                        sx={{ 
                            backgroundColor: "#0A722E", 
                            fontSize: "16px", 
                            borderRadius: "7px", 
                            height: "38px",
                            '&:hover': {
                                backgroundColor: "#0A722E" // Green color on hover
                            }
                        }} 
                        onClick={handleSubmit}
                    >
                        حفظ
                    </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>المعلومات الأساسية</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className="inputlable">الاسم</InputLabel>
                                    <TextField 
                                      size="small"
                                       label="الاسم" 
                                        variant="outlined" 
                                         fullWidth
                                        name="name" 
                                        value={formData.name} 
                                        onChange={(e)=>{handleChange("name",e.target.value)}} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className="inputlable">الايميل </InputLabel>
                                    <TextField
                                        label="الايميل"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        value={formData.email}
                                        onChange={(e) => { handleChange("email", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className="inputlable"> الرقم</InputLabel>
                                    <TextField
                                        label=" الرقم"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        type="number"
                                        value={formData.phone}
                                        onChange={(e) => { handleChange("phone", e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
            Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container sx={{ marginBottom: "20px" }}>
                <Card>
                    <CardContent>
                        <h3>{`${lang.name} المعلومات `}</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                <InputLabel className="inputlable">الاسم</InputLabel>
                                    <TextField 
                                       label="الاسم" 
                                          size="small"
                                        variant="outlined" 
                                        fullWidth 
                                        name="name" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.name?.value} 
                                        onChange={(e)=>{handleChange("name",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.name.id,0)}}
                                    />
                                    {/* dataToEditorState[JSON.stringify(lang.id)].name.id */}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <InputLabel className="inputlable">الوصف</InputLabel>
                                    <ReactQuill 
                                        theme="snow"
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.description?.value } 
                                        onChange={(e)=>{handleChange("description",e,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.description.id,1)}}  
                                        style={{ height: '150px',marginBottom:"40px" }}
                                        modules={Constant}

                                   />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <InputLabel className="inputlable">العنوان</InputLabel>
                                    <ReactQuill
                                        theme="snow"
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.description.value || ""}
                                        onChange={(value) => { handleChange("address", value, lang.id, null, 1) }}
                                        style={{ height: '150px',marginBottom:"40px" }}
                                        modules={Constant}
                                    />
                                </Grid>
                                
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>))}
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>رفع صورة</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                        <Files
                                        className='files-dropzone fileContainer'
                                        onChange={onFilesChange}
                                        onError={onFilesError}
                                        accepts={['image/*']}
                                        multiple={false}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                        >
                                                {
                                                    files.length > 0
                                                        ? <div style={{textAlign:"center"}}>
                                                            {files.map((file, index) =>
                                                                <div key={index}>
                                                                    <img width="400px" height="200px"  alt= "img" src= {`${file.preview.url}`}  />
                                                                </div>
                                                            )}
                                                        </div>

                                                        : <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                                                            <Button sx={{backgroundColor:"#0A722E",color:"white",padding:"10px",'&:hover': {  backgroundColor: "#0A722E",color:"white"  }}} >رفع صورة</Button>
                                                        </div>
                                                }
                                        </Files>
                                            {files.length > 0 ?
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                                                    <Button  onClick={() => deleteFile(files)} sx={{backgroundColor:"red",color:"white",padding:"8px 16px",'&:hover': {  backgroundColor: "red",color:"white"  }}}    >
                                                        Delete
                                                    </Button>
                                                </div> : ''
                                            }
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>رفع صورة الغلاف</h3>
                        <Files
                            className='files-dropzone fileContainer'
                            onChange={onCoverFilesChange}
                            onError={onCoverFilesError}
                            accepts={['image/*']}
                            multiple={false}
                            maxFileSize={10000000}
                            minFileSize={0}
                            clickable
                        >
                            {
                                coverPhoto ?
                                    <div style={{ textAlign: "center" }}>
                                        <img width="400px" height="200px" alt="cover-img" src={`${coverPhoto?.preview?.url}`} />
                                    </div>
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }}>
                                        <Button sx={{ backgroundColor: "#0A722E", color: "white", padding: "10px", '&:hover': { backgroundColor: "#0A722E", color: "white" } }} >
                                            رفع صورة الغلاف
                                        </Button>
                                    </div>
                            }
                        </Files>
                        {coverPhoto && coverPhoto.length > 0 ? 
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }}>
                                            <Button onClick={() => deleteFilecover(coverPhoto)} sx={{ backgroundColor: "red", color: "white", padding: "8px 16px", '&:hover': { backgroundColor: "red", color: "white" } }}    >
                                                Delete
                                            </Button>
                                        </div> : ''
                                    }

                    </CardContent>
                </Card>
             </Container>
            </>
        )


}
export default EditVendor;