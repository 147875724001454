import React , {useState , useEffect , Fragment} from "react";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LinearProgress from '@mui/material/LinearProgress';
import {Typography,Container,Card,CardContent,Box,Grid,Button,  TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TablePagination from '@mui/material/TablePagination';
import {  Select, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Orders = () => {

    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [recordIdToDelete, setRecordIdToDelete] = useState(null);
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const [status, setstatus] = useState([]);
    const [order, setorder] = useState([])

    let BaseApi = ""


    useEffect(()=>{

      const user = JSON.parse(localStorage.getItem("user"))
      const permissions = user?.permission

      if(permissions.includes("order.get")){
        BaseApi =  api_Routes.order.view
      }
      else if (permissions.includes("order.get-vendor")){
        BaseApi =  api_Routes.order.viewforvendor
      }
      else if (permissions.includes("order.get-delivery")){
        BaseApi =  api_Routes.order.viewfordeliveryboy
      }
        const controller = new AbortController()
        const signal = controller.signal
  
        get_order(signal)
        get_status()
  
         return()=>{
          controller.abort()
        }
  
        },[values_Filter,page,perPage])

    const get_status = () => {
        const response = { data: [
           { name: "Pending", id: 1 },
           { name: "Delivery", id: 2 },
           { name: "Delivered", id: 3 },
           { name: "Canceled", id: 5 },
           { name: "Rejected", id: 4 },
         ]};
           setstatus([]);
         response.data.forEach(ele => {
         
           setstatus(prev => [...prev, {
             label: ele.name,
             value: ele.id
           }]);
          
    });}

    const get_order = async (signal) => {

        let url = BaseApi + '?1=1';

        if (values_Filter.username ) {
          url = url + `&username=${values_Filter.username}`;
        }
        if (values_Filter.delivery_boy_name ) {
          url = url + `&delivery_boy_name=${values_Filter.delivery_boy_name}`;
        }
        if (values_Filter.status) {
            url = url + `&status=${values_Filter.status}`;
        }
        if (values_Filter.price) {
            url = url + `&price=${values_Filter.price}`;
        }
  
          setIsLoading(true)
        
        const {response, message} = await Helper.Get_Abort({
        
            url: url,
            hasToken:true,
            signal:signal,
           
              data:{
                results:perPage,
                page:page,                
              }
        })
        if(response){

            setorder([])
            setTotalItems(response.meta.total)

              response.data.forEach(elem => {
                  setorder(prev=>[...prev,{
                    id:elem.id,
                    username:elem.username,
                    price:elem.price,
                    discount:elem.discount,
                    delivery_boy_name:elem.delivery_boy_name,
                    order_status_name:elem.order_status_name,
                    action: (
                        <div>

                          <span style={{paddingRight:"15px"}} onClick={()=>{navigate(`/Order/${elem.id}`)}}>
                            <VisibilityIcon className="edit-icon"/>
                          </span>

                        </div>
                      ),
                    }])});

            setIsLoading(false)
          }else{
              console.log(message);
          }


    }

    const handleChangePage = (value)=>{
        console.log("per_pages",value);
        setPerPage(value)
    }

    const handlePage =(e)=>{
        setPage(e)
    }

    const handleChange = (key, value) => {
        setValues(prev => ({ ...prev, [key]:value }));
     }

    const handleFilter = ()=>{
        setFilter_Values(values)
    }

    return(
         <>


        <Container sx={{marginBottom:"20px"}}>
            <Grid container sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <Grid item>
                  <Typography sx={{fontSize:"28px" , fontWeight:"600" , color:"#1e1b1b"}} >الطلبيات</Typography>
                </Grid>
            
            </Grid>
            <Card sx={{marginTop:"20px"}}>
          <CardContent>
             <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                          <TextField 
                              id="filled-basic"
                              label="ابحث عن طريق صبي التسليم" 
                              variant="standard" 
                              name="origin" 
                              color="primary"
                              size="small"
                              onChange={(e)=>{handleChange("delivery_boy_name",e.target.value)}} 
                          />
                  </Grid>                 
                  <Grid item xs={12} sm={3}>
                          <TextField 
                              id="filled-basic"
                              label="ابحث عن طريق اسم المستخدم" 
                              variant="standard" 
                              name="origin" 
                              color="primary"
                              size="small"
                              onChange={(e)=>{handleChange("username",e.target.value)}} 
                          />
                  </Grid>                 
                  <Grid item xs={12} sm={3}>
                          <TextField 
                              type="number"
                              id="filled-basic"
                              label="ابحق عن طريق السعر" 
                              variant="standard" 
                              name="price" 
                              color="primary"
                              size="small"
                              onChange={(e)=>{handleChange("price",e.target.value)}} 
                          />
                  </Grid>                 
                  <Grid item xs={12} sm={3}>
                   <FormControl variant="standard" sx={{  width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">ابحث عن طريق الحالة  </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            color="primary"
                            label=""
                            onChange={(e) => { handleChange("status", e.target.value) }}
                            sx={{ width: "100%" }}
                            >
                            {status.map((chicken) => (
                                <MenuItem key={chicken.value} value={chicken.value}>{chicken.label}</MenuItem>
                            ))}
                       </Select>
                   </FormControl>
                  </Grid>
                  <Grid item  xs={12} sm={1} sx={{marginTop:"5px"}} >
                      <Button variant="contained" sx={{backgroundColor:"#0A722E",textAlign:"right" ,fontSize:"13px",borderRadius:"7px",height:"38px",'&:hover': {  backgroundColor: "#0A722E"  }}} onClick={()=> {handleFilter()}}>
                         <ManageSearchIcon />
                      </Button>
                  </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
        </Container>

        {isLoading ? <LinearProgress color="success" sx={{marginTop:"30px"}} /> : 
        <Container fluid={true}>
                  <Card>
                    <CardContent>
                        <Fragment>
                            <TableContainer component={Paper} sx={{ marginTop: '30px' }} >
                                <Table>
                                    <TableHead sx={{backgroundColor:"#0A722E !important", color:"white !important"}}>
                                        <TableRow>
                                            <TableCell> المعرف </TableCell>
                                            <TableCell> اسم المستخدم </TableCell>
                                            <TableCell> السعر </TableCell>
                                            <TableCell> التخفيضات </TableCell>
                                            <TableCell> الحالة </TableCell>
                                            <TableCell>موظف التوصيل  </TableCell>
                                            <TableCell> العمليات </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {order.map((ad) => (
                                            <TableRow key={ad.id}>
                                                <TableCell>{ad.id}</TableCell>
                                                <TableCell>{ad.username}</TableCell>
                                                <TableCell>{ad.price}</TableCell>
                                                <TableCell>{ad.discount}</TableCell>
                                                <TableCell>{ad.order_status_name}</TableCell>
                                                <TableCell>{ad.delivery_boy_name}</TableCell>
                                                <TableCell>{ad.action}</TableCell>
                                                            
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> 
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50 , 100]}
                              component="div"
                              count={totalItems} 
                              rowsPerPage={perPage} 
                              page={page - 1} 
                              onPageChange={(event, newPage) => handlePage(newPage + 1)} 
                              onRowsPerPageChange={(event) => {
                                setPerPage(parseInt(event.target.value, 10));
                                setPage(1); 
                              }}
                            />                         
                        </Fragment>
                    </CardContent>
                </Card>
        </Container>
      }
    
    
    
    
    
    </>)

}
export default Orders;