import React, { useState, useEffect } from "react";
import { Helper } from "../../Tools/Helper";
import { api_Routes } from "../../api_Route";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import StoreIcon from '@mui/icons-material/Store';

export default function Carts() {
    const [count, setCount] = useState({});
    const [loading, setLoading] = useState(true);
    const theme = useTheme(); // Access Material-UI's theme

    const get_counts = async () => {
        const { response } = await Helper.Get({
            url: api_Routes.statistics.count,
            hasToken: true
        });
        if (response) {
            setCount(response.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        get_counts();
    }, []);

    const cardsData = [
        { title: "مجموع المنتجات", value: count.products_total, icon: <StoreIcon fontSize="large" />, bgColor: theme.palette.error.main },
        { title: "مجموع الطلبيات", value: count.orders_total, icon: <ShoppingCartIcon fontSize="large" />, bgColor: theme.palette.success.main },
        { title: "مجموع المستخدمين", value: count.users_total, icon: <PeopleIcon fontSize="large" />, bgColor: theme.palette.info.main },
        { title: "مجموع الأصناف", value: count.categories_total, icon: <CategoryIcon fontSize="large" />, bgColor: theme.palette.warning.main }
    ];

    return (
        <Grid container spacing={3}>
            {cardsData.map((card, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <Card sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '16px',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        borderRadius: '12px',
                        backgroundColor: card.bgColor,
                        color: '#fff'
                    }}>
                        {loading ? (
                            <>
                                <Skeleton variant="circular" width={60} height={60} />
                                <CardContent sx={{ padding: '8px 0', marginRight: '16px' }}>
                                    <Skeleton variant="text" width={80} height={30} />
                                    <Skeleton variant="text" width={120} height={20} />
                                </CardContent>
                            </>
                        ) : (
                            <>
                                <Box sx={{
                                    marginLeft: '16px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '54px',
                                    height: '54px',
                                    borderRadius: '50%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.2)'
                                }}>
                                    {card.icon}
                                </Box>
                                <CardContent sx={{ padding: '8px 0' }}>
                                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                                        {card.value}
                                    </Typography>
                                    <Typography  sx={{ fontWeight: 'medium' }}>
                                        {card.title}
                                    </Typography>
                                </CardContent>
                            </>
                        )}
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}