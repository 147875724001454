import React, { useState, useEffect, Fragment , useRef} from "react";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useNavigate , useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Switch } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Files from 'react-files';

const AddOffer = () => {

    const { enqueueSnackbar } = useSnackbar();
    const { offerid } = useParams();
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const controllerRef = useRef(null)
    const [isLoadingRoles, setIsLoadingRoles] = useState(false); // New state for role loading
    const [formData, setFormData] = useState({
        price: "",
        percentage:"",
        from_date: "",
        to_date:"",
    })



    const handleChange = (key, value) => {

        setFormData(prev => ({ ...prev, [key]: value }));

    };

    const handleChanged = (key, value) => {
        const formattedDate = value ? new Date(value)
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ') 
        : null;  
        setFormData(prev => ({ ...prev, [key]: formattedDate }));
       }

    const handleSubmit = async () => {

        const requiredFields = [
            "from_date" ,  "to_date" ,
            
        ];

        const missingFields = requiredFields.filter((field) => !formData[field]);

        if (missingFields.length > 0 || (formData.percentage != "" && formData.price != "") ) {
            let errorMessage = "";

            if (missingFields.length > 0) {
                errorMessage += `Please fill in all required fields: ${missingFields.join(", ")}. `;
            }
            if(formData.percentage != "" && formData.price != "" )
            {
                errorMessage += `Add Price or Percentage not Both`;
            }

            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });

            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        var form_data = new FormData();
        var updatedFormData = { ...formData };
        var valueTemp = ''

        Object.keys(updatedFormData).forEach((key) => {
            // if (key === "price" && updatedFormData[key] != "" )
            //     form_data.append("percentage", "");

            // else if (key === "percentage" && updatedFormData[key] != "")
            //      form_data.append("price", "");
        
            // else
                form_data.append(key, updatedFormData[key]);
        });


        form_data.append("_method", "PUT");
        form_data.append("product_id",offerid );


        const { response, message } = await Helper.Post({
            url: api_Routes.offer.add,
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, {
                variant: "success",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            navigate(-1);
            setIsLoading(false);
        } else {
        
            enqueueSnackbar(message, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsLoading(false);
        }
    };





    return (
        <>
            <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>اضافة عرض</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            endIcon={isLoading ? <CircularProgress sx={{color:"white"}} size={22}/> : <AddIcon sx={{marginRight:"15px"}} />}
                            sx={{
                                backgroundColor: "#0A722E",
                                fontSize: "18px",
                                borderRadius: "7px",
                                height: "38px",
                                '&:hover': {
                                    backgroundColor: "#0A722E" // Green color on hover
                                }
                            }}
                            onClick={handleSubmit}
                        >
                            حفظ
                        </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>المعلومات الأساسية</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Price</InputLabel>
                                    <TextField
                                        type="number"
                                        sx={{ width: { xs: "100%", sm: "100%" } }}
                                        label="price"
                                        variant="outlined"
                                        size="small"
                                        value={formData.price}
                                        onChange={(e) => { handleChange("price", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    
                                </Grid>
                                
                                
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Percentage</InputLabel>
                                    <TextField
                                        type="number"
                                        sx={{ width: { xs: "100%", sm: "100%" } }}
                                        label="percentage"
                                        variant="outlined"
                                        size="small"
                                        value={formData.percentage}
                                        onChange={(e) => { handleChange("percentage", e.target.value) }}
                                    />
                                </Grid>
                               
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable" >Start Date</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={['DatePicker']} >
                                            <DatePicker
                                                
                                                selected={formData.from_date}
                                                dateFormat="M-d-yyyy"
                                                onChange={(date) => handleChanged("from_date", date)}
                                                sx={{ overFlow:'hidden',paddingTop:"-8px !important" , WebkitFlexDirection: 'row', display: 'contents' , borderRadius : '5px' , border: ' 1px solid  #e5e7eb' }}
                                                />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    
                                </Grid>
                                
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable" >End Date</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={['DatePicker']} >
                                            <DatePicker
                                                
                                                selected={formData.to_date}
                                                dateFormat="M-d-yyyy"
                                                onChange={(date) => handleChanged("to_date", date)}
                                                sx={{ overFlow:'hidden',paddingTop:"-8px !important" , WebkitFlexDirection: 'row', display: 'contents' , borderRadius : '5px' , border: ' 1px solid  #e5e7eb' }}
                                                />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
         
        </>
    )


}
export default AddOffer