import React, { useState, useEffect } from 'react';  
import { Link, useLocation } from 'react-router-dom';  
import logo from "../../assets/Logo.png";  
import GridViewIcon from '@mui/icons-material/GridView';  
import PersonIcon from '@mui/icons-material/Person';  
import LockIcon from '@mui/icons-material/Lock';  
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';  
import PublicIcon from '@mui/icons-material/Public';  
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';  
import AutoStoriesIcon from '@mui/icons-material/AutoStories';  
import SegmentIcon from '@mui/icons-material/Segment';  
import CategoryIcon from '@mui/icons-material/Category';  
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';  
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';  
import SettingsIcon from '@mui/icons-material/Settings';  
import BorderAllIcon from '@mui/icons-material/BorderAll';  
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';  
import ArticleIcon from '@mui/icons-material/Article';  
import CloseIcon from '@mui/icons-material/Close';  
import PeopleIcon from '@mui/icons-material/People';  

const Sidebar = ({ isOpen, toggleSidebar }) => {  
    const location = useLocation();  
    const isLoginPage = (location.pathname === "/login");  
    const [activeItem, setActiveItem] = useState(location.pathname);  
    const user = JSON.parse(localStorage.getItem("user"));  
    const permissions = user?.permission;  

    useEffect(() => {  
        const contentElement = document.querySelector('.content');  
        if (isOpen && !isLoginPage) {  
            contentElement.style.paddingRight = '225px';  
        } else {  
            contentElement.style.paddingRight = '0px';  
        }  

        setActiveItem(location.pathname);  
    }, [isOpen, location.pathname]);  

    const menuItems = [  
        { path: '/dashboard', label: 'لوحة التجكم', icon: <GridViewIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'setting.get' },  
        { path: '/settings', label: 'الاعدادات', icon: <SettingsIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'setting.get' },  
        { path: '/Cities', label: 'المدن', icon: <PublicIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'city.get' },  
        { path: '/Role', label: 'الأدوار', icon: <LockIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'role.get' },  
        { path: '/admins', label: 'المدير', icon: <PersonIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'admin.get' },  
        { path: '/vendors', label: 'الموردين', icon: <PeopleIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'vendor.get' },  
        { path: '/DeliveryBoys', label: 'موظفين التوصيل', icon: <DeliveryDiningIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'delivery_boy.get' },  
        { path: '/orders', label: 'الطلبيات', icon: <ShoppingCartIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permissions: ['order.get-vendor', 'order.get',"order.get-delivery"] },  
        { path: '/products', label: 'المنتجات', icon: <ProductionQuantityLimitsIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permissions: ['product.get','product.get-vendor'] },  
        { path: '/categories', label: 'الأصناف', icon: <CategoryIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'category.get' },  
        { path: '/Banners', label: 'سلايدرات', icon: <ViewCarouselIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'banner.get' },   
        { path: '/blogs', label: 'مدونات', icon: <ArticleIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'blog.get' },  
        { path: '/page', label: 'الصفحات', icon: <AutoStoriesIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'page.get' },  
        { path: '/section', label: 'أقسام الصفحات', icon: <SegmentIcon className='icon' sx={{ fontSize: "22px", marginLeft: "20px" }} />, permission: 'page_section.get' },  
    ];  

    const hasPermission = (requiredPermissions) => {  
        return requiredPermissions.some(permission => permissions?.includes(permission));  
    };  

    const filteredMenuItems = menuItems.filter(item =>   
        item.permissions ? hasPermission(item.permissions) : permissions?.includes(item.permission)  
    );  

    return (  
        <>  
            <div className={`menu ${isOpen ? '' : 'hidden'}`}>  
                {isOpen ?  
                    <button onClick={toggleSidebar} className="toggle-button-open">  
                        <CloseIcon fontSize="small" className="spin-icon" sx={{ color: "green !important" }} />  
                    </button> : ""  
                }  
                <div className='logo'>  
                    <img className='logosidebar' src={logo} alt="logo" />  
                </div>  
                <div className='menu--list'>  
                    {filteredMenuItems.map(item => (  
                        <Link key={item.path} to={item.path} className={`item ${activeItem === item.path ? 'active' : ''}`}>  
                            <span>  
                                {item.icon}  
                                {item.label}  
                            </span>  
                        </Link>  
                    ))}  
                </div>  
            </div>  
        </>  
    );  
};  

export default Sidebar;